export let config: {
    GRAPH_REALTIME_ENDPOINT: string;
    GRAPH_ENDPOINT: string;
    REPORT_DOWNLOAD_ENDPOINT: string;
    DOCUMENTS_ENDPOINT: string;
    DOCUMENT_UPLOAD_ENDPOINT: string;
    CASINO_CONFIG_INTEGRATIONS_IMPORT_ENDPOINT: string;
    CASINO_CONFIG_INTEGRATIONS_EXPORT_ENDPOINT: string;
    SPORTS_CONFIG_INTEGRATIONS_IMPORT_ENDPOINT: string;
    SPORTS_CONFIG_INTEGRATIONS_EXPORT_ENDPOINT: string;
    SPORTS_CONFIG_PROVIDER_IMPORT_ENDPOINT: string;
    SPORTS_CONFIG_PROVIDER_EXPORT_ENDPOINT: string;
    PAYMENT_CONFIG_INTEGRATIONS_IMPORT_ENDPOINT: string;
    PAYMENT_CONFIG_INTEGRATIONS_EXPORT_ENDPOINT: string;
    CASINO_CONFIG_PROVIDERS_IMPORT_ENDPOINT: string;
    CASINO_CONFIG_PROVIDERS_EXPORT_ENDPOINT: string;
    CASINO_CONFIG_GAMES_IMPORT_ENDPOINT: string;
    CASINO_CONFIG_GAMES_EXPORT_ENDPOINT: string;
    PAYMENT_CONFIG_PROVIDERS_IMPORT_ENDPOINT: string;
    PAYMENT_CONFIG_PROVIDERS_EXPORT_ENDPOINT: string;
    PAYMENT_CONFIG_METHODS_IMPORT_ENDPOINT: string;
    PAYMENT_CONFIG_METHODS_EXPORT_ENDPOINT: string;
    AUTH_ENDPOINT: string;
    AUTH_CLIENT_ID: string;
    SEARCH_ENDPOINT: string;
    DEFAULT_LANGUAGE: string;
    DEFAULT_LOCALE: string;
    CMS_ASSET_URL: string;
    AVAILABLE_LANGUAGES: string[];
    AVAILABLE_LOCALES: string[];
    AVAILABLE_SELF_EXCLUSION_DURATIONS: ILimitDurationConfig[];
    AVAILABLE_TIMEOUT_DURATIONS: ILimitDurationConfig[];
    RESTRICTIONS_REASONS: [{ REASON: string; RESTRICTS: string[] }];
    JURISDICTION_CONFIG: { JURISDICTION: { AVAILABLE_SESSION_LIMITS: string[] } };
    ENVIRONMENT: string;
};

export interface ILimitDurationConfig {
    MONTHS?: number;
    DAYS?: number;
    HOURS?: number;
    value: string;
}

export interface IJurisdictionsConfig {
    code: string;
    name: string;
}

export interface ICurrenciesConfig {
    code: string;
    name: string;
}

export async function loadConfig(): Promise<void> {
    const request = await fetch('/config.json');
    const response = await request.json();
    config = response;
}
